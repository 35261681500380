.pagination {
    display: flex;
    gap: 10px; /* Adds spacing between pagination items */
  }
  
  .page-item.disabled .page-link {
    background-color: #ccc;
    pointer-events: none;
  }
  
  .page-item .page-link {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    color: #007bff;
    text-decoration: none;
  }
  
  .page-item.active .page-link {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  