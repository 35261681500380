.list-group-item {
    border: none;
    padding-left: 0;
  }

  
  .card>.list-group {
    border-top: none; 
}
.list-group.no-border {
  border: none;
}