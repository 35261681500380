h1 {
    font-weight: bold;
    margin-bottom: 30px;
}

h2, h3 {
    margin-top: 40px;
    margin-bottom: 15px;
}

ul {
    list-style-type: disc;
    margin-left: 20px;
}

p {
    line-height: 1.6;
    margin-bottom: 20px;
}
