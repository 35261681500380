

/* Change color for active nav-link */
.nav-link.active {
    color: #009800 !important; /* Replace with your desired active color */
    font-weight: bold; /* Optional: Makes the active link bold */
}

/* Optional: Change hover background color */
.navbar-nav .nav-item .nav-link:hover {
    background-color: #f8f9fa !important; /* Replace with your desired background color */
    border-radius: 5px; /* Adds rounded corners */
    color: #009800 !important; /* Replace with your desired active color */
}

#navbar{
    /* background-color: rgba(0, 0, 0, 0.1); Black with 50% opacity */
    /* border-bottom: 1px solid black; */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow for elevation effect */
    
}
.bg-dark-opacity {
    background-color: rgba(0, 0, 0, 0.5) !important; /* 50% opacity */
}