/* src/DeviceSelection.css */
body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 900px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    color: #333;
}

.cards {
    display: flex;
    justify-content: space-between;
}

.card {
    flex: 1;
    margin: 10px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
}

.card h3 {
    margin-bottom: 20px;
    color: #333;
}

.device-image {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
}

.form-submit {
    text-align: center;
    margin-top: 20px;
}

.form-submit button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-submit button:hover {
    background-color: #0056b3;
}

@media (max-width: 600px) {
    .cards {
        flex-direction: column;
    }
}
.fixed-height-card {
    height: 300px; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .trade-in-section {
    background-color: #f8f9fa; /* Light background */
    padding: 2rem;
    border-radius: 8px;
  }
  
  .trade-in-section h2 {
    font-weight: bold;
  }
  
  .trade-in-section h3 {
    color: #333;
  }
  
  .trade-in-section p {
    color: #555;
    line-height: 1.6;
  }
  .separator {
    border: none;
    border-top: 1px solid #ccc;
    margin: 1.5rem 0;
    opacity: 0.6;
    width:100%;                                                   
  }
  .flip-card {
    perspective: 1000px;
  }
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  .flip-card-back {
    transform: rotateY(180deg);
    /* background-color: #f8f9fa; */
    color: #212529;
    padding: 20px;
  }
  .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* Ensure the modal takes up full vertical space */
}
.modal-body-scrollable {
  max-height: 70vh; /* Adjust the max height as needed */
  overflow-y: auto;
}
.modal-body-scrollable {
  max-height: 70vh; /* Keep modal body height limited */
  overflow-y: auto;
}

.fixed-card-height {
  height: 200px; /* Set a fixed height for all cards */
}

.fixed-card-height .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.high-z-index-modal .modal-content {
  z-index: 1055; /* Increase this if needed */
}